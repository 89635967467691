<template>
    <input
        :id="id"
        ref="input"
        :name="name"
        :value="value"
        class="form-input"
        type="time"
    >
</template>

<script>
    import $ from 'jquery';
    import 'timepicker';

    export default {
        props: {
            id: {
                type: String,
                default: null,
            },

            name: {
                type: String,
                default: null,
            },

            value: {
                type: String,
                default: null,
            },

            seconds: {
                type: Boolean,
                default: true,
            },
        },

        watch: {
            value(value) {
                this.$input.val(value.substr(0, 5));
            },
        },

        mounted() {
            this.$input = $(this.$refs.input);

            // Pre-fill
            if (this.value) {
                this.$input.val(this.value.substr(0, 5));
            }

            if (!this.useNativeTimepicker()) {
                this.$input
                    .attr('pattern', '^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')
                    .attr('title', this.$t('masterfiles.messages.hint-time'))
                    .attr('type', 'text')
                    .timepicker({
                        timeFormat: 'H:i',
                        step: 15,
                        scrollDefault: 'now',
                    }).on('changeTime', () => {
                        let value = this.$input.val();

                        if (this.seconds !== false) {
                            value = value + ':00';
                        }

                        this.$emit('input', value);
                        this.$emit('change', value);
                    });
            }
        },

        methods: {
            useNativeTimepicker() {
                return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
            },
        },
    };
</script>
